import axios from "axios";

import Auth from "./Auth";
import TokenStorage from "../helpers/tokenStorage";
import User from "./User";

export default class Api {
    baseUrl = "https://mazuri-inventory-dev.fgp.digital/api/v1";
    auth: Auth;
    user: User

    constructor() {
        this.auth = new Auth(this.request, this.baseUrl);
        this.user = new User(this.request, this.baseUrl)
    }

    // инстанс запросов через который буду выполятся все запросы на бекенд
    get request() {
        const instance = axios.create();

        instance.interceptors.request.use((config) => {
            // TODO: get token from storage
            const token = TokenStorage.getToken();

            if (token) {
                (config.headers as any).common["Authorization"] = `Bearer ${token}`;
            }

            return config;
        });

        return instance;
    }
}
